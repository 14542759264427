<template>
  <div class="login-main wallet-main">
    <nav-bar></nav-bar>
    <section class="signup-start-main pb-4 box-flex">
      <div class="container login-container review-main text-capitalize position-relative  box-flex__wrapper">
        <div class="row">
          <div class="col-md-12">
            <div class="center-card">
              <div class="center-card-box | top-sticky  border-bottom">
                <div class="sign-main wallet_main p-3 border-bottom">
                  <div class="wallet-top">
                    <h4 class="mb-1">{{ $t('My Wallet') }}</h4>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div>
                      <p class="mybal">{{ $t('My Balance') }}</p>
                      <p class="amount"><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{ walletAmount }}</p>
                    </div>
                    <div class="btn full-btn" v-if="$auth.setting && $auth.user && $auth.user.wallet_status == 'active' && $auth.setting.wallet_status == 'active' && $auth.setting.customer_wallet_recharge == 'active'">
                      <router-link :to="{ name: 'wallet-payment' }" class="back-btn btn">{{ $t('Add Money') }}</router-link>
                    </div>
                  </div>
                </div>
                <div class="trans-sec py-2 px-3">
                  <span class="trans-heading fw-semibold">{{ $t('Transactions') }}</span>
                </div>
              </div>
              <div class="center-card-box px-3 py-2">
                <div class="trans-sec">
                  <div class="wlt-ordr-list mt-5" v-if="isSkeletonLoader">
                    <div class="wallet-detail w-100">
                      <p class="mb-2"><skeleton-loader-vue type="string" width="24%" height="20px" /></p>
                      <p class="mb-2"><skeleton-loader-vue type="string" width="30%" height="20px" /></p>
                      <p class="wlt-date"><skeleton-loader-vue type="string" width="24%" height="20px" /></p>
                    </div>
                    <div class="waltet-deduct">
                      <skeleton-loader-vue type="string" width="15%" height="20px" />
                    </div>
                  </div>
                  <template v-else>
                    <div class="wlt-ordr-list mt-5" v-for="(history, key) in historys" :key="key">
                      <div class="wallet-detail w-100">
                        <p style="font-weight: 600;" v-if="history.type == 'refund' || history.type == 'spent' || history.type == 'cashback'">
                          {{ $t(history.type) }} (Order #{{ history.user_order_id }})</p>
                        <p style="font-weight: 600;" v-else>{{ $t(history.type) }}</p>
  
                        <p>{{ $t('Transaction ID #') }}<span>{{ history.order_id }}</span></p>
  
                        <p class="wlt-date">{{ date_convert(moment(history.order_date).format('MMM-D-YYYY, HH:mm:ss')) }}
                        </p>
                      </div>
                      <div class="waltet-deduct">
                        <p class="deduct-prc clr-red" v-if="['spent'].includes(history.type)" :class="history.type">-<span
                            v-html="$auth.setting.currency"
                            class="currency"></span>{{ (parseFloat(history.amount)).toFixed(2) }}</p>
                        <p class="deduct-prc clr-grn" v-else>+<span v-html="$auth.setting.currency"
                            class="currency"></span>{{ (parseFloat(history.amount)).toFixed(2) }}</p>
                      </div>
                    </div>
                    <div v-if="historys.length == 0" class="no-tranc">
                      <p>{{ $t('No Transactions Found') }}</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import NavBar from "@/components/NavBar.vue";
import Footer from '../components/Footer.vue';
import Wallet from '../apis/wallet';
import moment from "moment";
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  components: {
    NavBar,
    Footer,
    'skeleton-loader-vue': VueSkeletonLoader
  },

  data() {
    return {
      historys: [],
      moment: moment,
      walletAmount: 0,
      isSkeletonLoader: true,
      walletStatus:''
    };
  },
  mounted() {
    setTimeout(() => {            
      // this.getWalltet();
      if(this.$auth && this.$auth.user){
        this.walletStatus =  this.$auth.user.wallet_status;
      }
      this.getWalletHistory();
    }, 1000);
    
  },
  methods: {    
    ...mapActions("user", ["getuserWallet"]),

    getWalletHistory() {
      if(this.$auth && this.$auth.user){        
        Wallet.gerUserWalletHistory({
          'user_id': this.$auth.user.user_id,
          'is_langauge': this.$store.state.lang.locale,
          'vendor_id': this.$auth.getVendorId()
        }).then((response) => {
          if (response.data.code == 200) {          
            this.historys = response.data.Result;
            this.walletAmount = response.data.wallet_amount;
          } else {          
            this.historys = [];
          }
          this.isSkeletonLoader = false;
        });
      }
    },

    date_convert(date) {
      return this.$t(date.substring(0, 3)) + date.slice(3);
    }
  },
};
</script>